import React from "react";

import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import Section from "../components/section";
import params from "../data/params";

const PrivacyPolicy = (props) => {
    return <Layout staticHeader>
        <SEO title="Política de privacidad" description="" location={props.location}/>

        <Section title="Política de privacidad">
            <div className="container" style={{marginBottom: "45px"}}>

                <h3>1.- &iquest;Qui&eacute;n es el responsable del tratamiento de sus datos? </h3>
                <p>El responsable del tratamiento de sus datos personales es ADVENTUREES ALLIANCE, S.L. (en adelante, &ldquo;<strong><em>ADVENTUREES ALLIANCE</em></strong>&rdquo;), con N.I.F. B-76712066 y domicilio social en la Calle Santa Teresa de Jornet e Ibars, 11, 38004, Santa Cruz de Tenerife.</p>
                <p>Le informamos que la titularidad del dominio de nuestra web <a target="_blank" rel="noreferrer noopener" href={params.BASE_URL}>{params.BASE_URL}</a> (en adelante el &ldquo;Sitio Web&rdquo;), es de ADVENTUREES ALLIANCE.</p>
                <p>El acceso y/o uso al Sitio Web le atribuye la condici&oacute;n de Usuario (en adelante, el "Usuario"), e implica la aceptaci&oacute;n, desde dicho acceso y/o uso, de la presente Pol&iacute;tica de Privacidad.</p>
                <p>El Usuario podr&aacute; contactar con ADVENTUREES ALLIANCE en la siguiente direcci&oacute;n de correo electr&oacute;nico: {params.CONTACT_EMAIL}.</p>
                <p>&nbsp;</p>
                <h3>2.- &iquest;Por qu&eacute; ADVENTUREES ALLIANCE est&aacute; legitimado para llevar a cabo el tratamiento de datos?</h3>
                <p>ADVENTUREES ALLIANCE est&aacute; legitimado para tratar sus datos para poder llevar a cabo la prestaci&oacute;n de sus servicios.</p>
                <p>ADVENTUREES ALLIANCE se toma muy en serio la protecci&oacute;n de su privacidad y de sus datos personales. Por lo tanto, su informaci&oacute;n personal se conserva de forma segura y se trata con el m&aacute;ximo cuidado.</p>
                <p>La presente Pol&iacute;tica de Privacidad regula el acceso y el uso al servicio (en adelante, el &ldquo;<strong><em>Servicio</em></strong>&rdquo;) que ADVENTUREES ALLIANCE pone a disposici&oacute;n del Usuario interesado en los servicios y Contenidos alojados en el Sitio Web.</p>
                <p>En este sentido, las bases de legitimaci&oacute;n utilizadas por ADVENTUREES ALLIANCE para el tratamiento de los datos de los Usuarios son:</p>
                <ul>
                    <li>La ejecuci&oacute;n contractual.</li>
                    <li>El consentimiento de los Usuarios al tratamiento de sus datos personales para las finalidades especificadas en cada caso.</li>
                    <li>El inter&eacute;s leg&iacute;timo.</li>
                </ul>
                <p>Por lo que respecta a este &uacute;ltimo, el Usuario podr&aacute; retirar el consentimiento en cualquier momento mandando un mensaje a la siguiente direcci&oacute;n de correo electr&oacute;nico: {params.CONTACT_EMAIL}, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada.</p>
                <p>&nbsp;</p>
                <h3>3.- &iquest;Cu&aacute;les son las finalidades del tratamiento de sus datos de car&aacute;cter personal por parte de ADVENTUREES ALLIANCE?</h3>
                <p>Sus datos de car&aacute;cter personal recabados por ADVENTUREES ALLIANCE podr&aacute;n ser utilizados para las siguientes finalidades:</p>
                <ul>
                    <li>La prestaci&oacute;n de los servicios ofrecidos por ADVENTUREES ALLIANCE.</li>
                    <li>El env&iacute;o de comunicaciones comerciales y/o Newsletter de ADVENTUREES ALLIANCE sobre sus servicios mediante carta, tel&eacute;fono, correo electr&oacute;nico, SMS/MMS, o por otros medios de comunicaci&oacute;n electr&oacute;nica equivalentes.</li>
                </ul>
                <p>&nbsp;</p>
                <h3>4.- Veracidad de los datos facilitados por los Usuarios y datos de menores</h3>
                <p>El Usuario garantiza que los datos personales facilitados son veraces y se hace responsable de comunicar a ADVENTUREES ALLIANCE cualquier modificaci&oacute;n de los mismos. El Usuario responder&aacute;, en cualquier caso, de la veracidad de los datos facilitados, reserv&aacute;ndose ADVENTUREES ALLIANCE el derecho a excluir de los Servicios registrados a todo Usuario que haya facilitado datos falsos, sin perjuicio de las dem&aacute;s acciones que procedan en Derecho.</p>
                <p>&nbsp;</p>
                <h3>5.-Conservaci&oacute;n de los datos</h3>
                <p>ADVENTUREES ALLIANCE conservar&aacute; los datos personales de los usuarios &uacute;nicamente durante el tiempo necesario para la realizaci&oacute;n de las finalidades para las que fueron recogidos, mientras el Usuario no revoque los consentimientos otorgados. Posteriormente, en caso de ser necesario, ADVENTUREES ALLIANCE mantendr&aacute; la informaci&oacute;n bloqueada los plazos legalmente establecidos.</p>
                <p>&nbsp;</p>
                <h3>6.- Derechos del Usuario en relaci&oacute;n con sus datos</h3>
                <p>El Usuario tiene derecho a:</p>
                <ul>
                    <li>Acceder a sus datos personales.</li>
                    <li>Solicitar la rectificaci&oacute;n de los datos inexactos</li>
                    <li>Solicitar su supresi&oacute;n.</li>
                    <li>Solicitar la limitaci&oacute;n del tratamiento de sus datos.</li>
                    <li>Oponerse al tratamiento de sus datos.</li>
                    <li>Solicitar su portabilidad.</li>
                    <li>No ser objeto de decisiones individuales automatizadas.</li>
                </ul>
                <p>El Usuario puede ejercer todos estos derechos en la siguiente direcci&oacute;n de email {params.CONTACT_EMAIL} indicando el motivo de su petici&oacute;n y aportando una copia de su D.N.I.</p>
                <p>El Usuario puede tambi&eacute;n enviar su petici&oacute;n por correo ordinario a la siguiente direcci&oacute;n:</p>
                <p>ADVENTUREES ALLIANCE, S.L.</p>
                <p>Calle Santa Teresa de Jornet e Ibars, 11</p>
                <p>38004, Santa Cruz de Tenerife.</p>
                <p>Sin perjuicio de cualquier otro recurso administrativo o acci&oacute;n judicial, el Usuario tendr&aacute; derecho a presentar una reclamaci&oacute;n ante una Autoridad de Control, en particular en el Estado miembro en el que tenga su residencia habitual, lugar de trabajo o lugar de la supuesta infracci&oacute;n, en caso de que considere que el tratamiento de sus datos personales no es adecuado a la normativa, as&iacute; como en el caso de no ver satisfecho el ejercicio de sus derechos. La autoridad de control ante la que se haya presentado la reclamaci&oacute;n informar&aacute; al reclamante sobre el curso y el resultado de la reclamaci&oacute;n.</p>
                <p>&nbsp;</p>
                <h3>7.- Seguridad de los datos</h3>
                <p>La protecci&oacute;n de la privacidad y los datos personales de los Usuarios es muy importante para ADVENTUREES ALLIANCE. Por lo tanto, ADVENTUREES ALLIANCE hace todo lo que est&aacute; en su mano para impedir que sus datos se utilicen de forma inadecuada, permitiendo el acceso a los mismo &uacute;nicamente a personal autorizado.</p>
                <p>ADVENTUREES ALLIANCE mantiene los niveles de seguridad de protecci&oacute;n de datos personales conforme a la normativa aplicable y ha establecido todos los medios t&eacute;cnicos a su alcance para evitar la p&eacute;rdida, mal uso, alteraci&oacute;n, acceso no autorizado y robo de los datos que el Usuario facilite a trav&eacute;s del Sitio Web, sin perjuicio de informarle de que las medidas de seguridad en Internet no son inexpugnables.</p>
                <p>ADVENTUREES ALLIANCE se compromete a cumplir con el deber de secreto y confidencialidad respecto de los datos personales de acuerdo con la legislaci&oacute;n aplicable.</p>
                <p>En aquellos servicios de la web que precisen registrarse como Usuario, se deber&aacute; elegir una contrase&ntilde;a. El Usuario es el responsable de mantener la confidencialidad de esta contrase&ntilde;a, as&iacute; como de todas las actividades que ocurran en la sesi&oacute;n iniciada con su nombre y contrase&ntilde;a.</p>
                <p>El Usuario se compromete a notificar a ADVENTUREES ALLIANCE a la mayor brevedad el uso no autorizado de su nombre de Usuario y/o contrase&ntilde;a o cualquier otro fallo en la seguridad. ADVENTUREES ALLIANCE no ser&aacute; responsable por los da&ntilde;os o p&eacute;rdidas que se pudieran originar debido al no cumplimiento de esta obligaci&oacute;n por parte del Usuario.</p>
                <p>&nbsp;</p>
                <h3>8.-Cambios</h3>
                <p>ADVENTUREES ALLIANCE se reserva el derecho de revisar su Pol&iacute;tica de Privacidad en el momento que considere oportuno. Por esta raz&oacute;n, le rogamos que compruebe de forma regular esta Pol&iacute;tica de Privacidad para leer la versi&oacute;n m&aacute;s reciente de la misma.</p>
                <p>No obstante, cualquier cambio que se produzca en la presente Pol&iacute;tica de Privacidad se comunicar&aacute; al Usuario.</p>
                <p>&nbsp;</p>
                <h3>9.- Links a p&aacute;ginas web</h3>
                <p>La Plataforma de ADVENTUREES ALLIANCE podr&iacute;a contener links a p&aacute;ginas web de compa&ntilde;&iacute;as y entidades de terceros.</p>
                <p>ADVENTUREES ALLIANCE no puede hacerse responsable de la forma en la que estas compa&ntilde;&iacute;as tratan la protecci&oacute;n de la privacidad y de los datos personales, por lo que le aconsejamos que lea detenidamente las declaraciones de Pol&iacute;tica de Privacidad de estas p&aacute;ginas web que no son titularidad de ADVENTUREES ALLIANCE con relaci&oacute;n al uso, procesamiento y protecci&oacute;n de datos personales.</p>
                <p>Las condiciones que ofrecen estas p&aacute;ginas web pueden no ser las mismas que las que ofrece ADVENTUREES ALLIANCE.</p>
                <p>&nbsp;</p>
                <h3>10.- Preguntas</h3>
                <p>Si tienen alguna pregunta sobre esta Pol&iacute;tica de Privacidad o el tratamiento de sus datos rogamos que se ponga en contacto con ADVENTUREES ALLIANCE mediante correo electr&oacute;nico dirigido a la siguiente direcci&oacute;n de correo electr&oacute;nico: {params.CONTACT_EMAIL}.</p>
                <p>&nbsp;</p>
                <h3>11.- Aceptaci&oacute;n y Consentimiento</h3>
                <p>El Usuario declara haber sido informado de las condiciones sobre protecci&oacute;n de datos personales, aceptando y consintiendo el tratamiento de estos por parte de ADVENTUREES ALLIANCE, en la forma y para las finalidades indicadas en la presente Pol&iacute;tica de Privacidad.</p>
            </div>
        </Section>
    </Layout>
}

export default PrivacyPolicy;
